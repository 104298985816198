import React from "react";
import { Link } from "gatsby";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubscribeForm from "../components/subscribeForm";
import {
  Button,
  HeaderPlaceholder,
  PaginationButton,
} from "../styles/ui.styles";
import { DoubleTitle, UnavailableOverlay } from "../styles/ui.styles";
import { FaArrowRight } from "react-icons/fa";
import { findHardwareId, useCartStore } from "../lib/store";
import { findPriceById, useStipeProductData } from "../lib/products";

import products from "../../content/products/products.json";
import { useSnackbar } from "notistack";

const Webshop: React.FC = () => {
  const stripeData = useStipeProductData();
  const { addAddon } = useCartStore();
  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string) => {
    enqueueSnackbar(message, {
      autoHideDuration: 3000,
      variant: "success",
      className: "snackbar",
    });
  };

  const handleAddAddon = (id?: string, name?: string | null) => {
    id && addAddon(id);
    name && showSnackbar(`Added ${name} to the cart`);
  };

  // * check if product is available
  const dinghyUnavailable = products
    .filter((product) => product.boatType === "dinghy")
    .every((prod) => prod.available === false);
  const yachtUnavailable = products
    .filter((product) => product.boatType === "yacht")
    .every((prod) => prod.available === false);
  const coachboatUnavailable = products
    .filter((product) => product.boatType === "coachboat")
    .every((prod) => prod.available === false);
  const databayUnavailable = products
    .filter((product) => product.productType === "software")
    .every((prod) => prod.available === false);

  return (
    <>
      <SEO title={"Products"} description={"Products"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <DoubleTitle normalTitle="Webshop" largeTitle="WEBSHOP" />
          <Grid>
            <Card>
              <CardImageWrapper>
                <StaticImage
                  style={{ width: "100%", height: "100%" }}
                  src={"../images/dinghy_edition.jpg"}
                  alt={"DinghyEdition"}
                  objectFit={"contain"}
                />
                {dinghyUnavailable && <UnavailableOverlay />}
              </CardImageWrapper>
              <CardInfoWrapper>
                <div>
                  <h4>DinghyEdition</h4>
                  <p>
                    from €
                    {
                      findPriceById(
                        findHardwareId("leisure", "dinghy"),
                        stripeData
                      ).euros
                    }
                    .
                    {
                      findPriceById(
                        findHardwareId("leisure", "dinghy"),
                        stripeData
                      ).cents
                    }
                  </p>
                </div>
                <Link to="/products/dinghy-edition">
                  <PaginationButton aria-label={"DinghyEdition"} primary={true}>
                    <FaArrowRight />
                  </PaginationButton>
                </Link>
              </CardInfoWrapper>
            </Card>

            <Card>
              <CardImageWrapper>
                <StaticImage
                  style={{ width: "100%", height: "100%" }}
                  src={"../images/yacht_edition.jpg"}
                  alt={"YachtEdition"}
                  objectFit={"contain"}
                />
                {yachtUnavailable && <UnavailableOverlay />}
              </CardImageWrapper>
              <CardInfoWrapper>
                <div>
                  <h4>YachtEdition</h4>
                  <p>
                    from €
                    {
                      findPriceById(
                        findHardwareId("leisure", "yacht"),
                        stripeData
                      ).euros
                    }
                    .
                    {
                      findPriceById(
                        findHardwareId("leisure", "yacht"),
                        stripeData
                      ).cents
                    }
                  </p>
                </div>
                <Link to="/products/yacht-edition">
                  <PaginationButton aria-label={"YachtEdition"} primary={true}>
                    <FaArrowRight />
                  </PaginationButton>
                </Link>
              </CardInfoWrapper>
            </Card>

            <Card>
              <CardImageWrapper>
                <StaticImage
                  style={{ width: "100%", height: "100%" }}
                  src={"../images/coach_boat_pro.jpg"}
                  alt={"CoachBoatPro"}
                  objectFit={"contain"}
                />
                {coachboatUnavailable && <UnavailableOverlay />}
              </CardImageWrapper>
              <CardInfoWrapper>
                <div>
                  <h4>CoachBoatPro</h4>
                  <p>
                    from €
                    {
                      findPriceById(
                        findHardwareId("racing", "coachboat"),
                        stripeData
                      ).euros
                    }
                    .
                    {
                      findPriceById(
                        findHardwareId("racing", "coachboat"),
                        stripeData
                      ).cents
                    }
                  </p>
                </div>
                <Link to="/products/coach-boat-pro">
                  <PaginationButton aria-label={"CoachBoatPro"} primary={true}>
                    <FaArrowRight />
                  </PaginationButton>
                </Link>
              </CardInfoWrapper>
            </Card>

            <Card>
              <CardImageWrapper>
                <StaticImage
                  style={{ width: "100%", height: "100%" }}
                  src={"../images/data_bay.jpg"}
                  alt={"DataBay"}
                  objectFit={"contain"}
                />
                {databayUnavailable && <UnavailableOverlay />}
              </CardImageWrapper>
              <CardInfoWrapper>
                <div>
                  <h4>DataBay</h4>
                  <p>starting free</p>
                </div>
                <Link to="/products/data-bay">
                  <PaginationButton aria-label={"DataBay"} primary={true}>
                    <FaArrowRight />
                  </PaginationButton>
                </Link>
              </CardInfoWrapper>
            </Card>

            {/* // * Additional products */}
            {products
              .filter((product) => product?.productType === "addon")
              .map((product) => {
                const prod = stripeData?.allStripePrice?.edges?.find(
                  (stripeProduct) => stripeProduct.node.id === product.id
                );
                return (
                  <>
                    <CardAddon>
                      <CardImageWrapper>
                        <GatsbyImage
                          style={{ width: "100%", height: "100%" }}
                          image={
                            getImage(
                              prod?.node.product?.localFiles?.[0]
                                ?.childImageSharp?.gatsbyImageData
                            ) as IGatsbyImageData
                          }
                          alt={prod?.node.product?.name ?? "Addon"}
                          objectFit={"contain"}
                        />
                        {!product?.available && <UnavailableOverlay />}
                      </CardImageWrapper>
                      <CardInfoWrapperAddon>
                        <div>
                          <h4>{prod?.node.product?.name}</h4>
                          <p>
                            €{prod?.node.unit_amount?.toString().slice(0, -2)}.
                            {prod?.node.unit_amount?.toString().slice(-2)}
                          </p>
                        </div>
                        <div className="cart-button">
                          <Button
                            aria-label={"Add to cart"}
                            primary={true}
                            disabled={!product?.available}
                            onClick={() =>
                              handleAddAddon(
                                prod?.node.id,
                                prod?.node.product?.name
                              )
                            }
                          >
                            {product?.available ? `ADD TO CART` : `UNAVAILABLE`}
                          </Button>
                        </div>
                      </CardInfoWrapperAddon>
                    </CardAddon>
                  </>
                );
              })}
          </Grid>
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Webshop;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
`;

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
  place-items: center;
  justify-content: center;

  @media (max-width: 906px) {
    grid-template-columns: 1fr;
    gap: 32px;
  }
`;

const Card = styled.div`
  position: relative;
  background: white;
  width: 500px;
  height: 500px;
  border-radius: 20px;
  box-shadow: var(--shadow);

  @media (max-width: 1165px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CardImageWrapper = styled.div`
  position: relative;
  padding: 32px;
  height: 380px;
  width: 100%;

  @media (max-width: 1165px) {
    height: 300px;
  }
`;
const CardInfoWrapper = styled.div`
  border-top: 2px solid var(--color-orange);
  padding: 20px;
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0.5rem;
  }

  @media (max-width: 1165px) {
    height: 100px;
  }
`;

const CardAddon = styled(Card)`
  height: 600px;
  @media (max-width: 1165px) {
    height: 500px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CardInfoWrapperAddon = styled(CardInfoWrapper)`
  flex-direction: column;
  width: 100%;
  height: 200px;
  align-items: flex-start;
  justify-content: space-between;

  .cart-button,
  button {
    width: 100%;
  }
`;
