import { useStaticQuery, graphql } from "gatsby";
import type { StipeProductDataQuery } from "../../graphql-types";

export const useStipeProductData: () => StipeProductDataQuery = () => {
  const stipeProductData = useStaticQuery(
    graphql`
      query StipeProductData {
        allStripePrice {
          edges {
            node {
              id
              active
              product {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 400)
                  }
                }
                name
              }
              type
              unit_amount
            }
          }
        }
      }
    `
  );
  return stipeProductData;
};

export const findPriceById: (
  id: string,
  stripeProductData: StipeProductDataQuery
) => { euros: string; cents: string; priceInCents: number } = (
  id: string,
  stripeProductData: StipeProductDataQuery
) => {
  const product = stripeProductData.allStripePrice.edges.find(
    (node) => node.node.id === id
  );
  let euros = "0";
  let cents = "00";
  let priceInCents = 0;
  if (product?.node.unit_amount) {
    euros = product.node.unit_amount.toString().slice(0, -2);
    cents = product.node.unit_amount.toString().slice(-2);
    priceInCents = product.node.unit_amount;
  }
  return { euros, cents, priceInCents };
};
